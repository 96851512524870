<!--  -->
<template>
    <div class="rela">
        <!-- <el-tabs :tab-position="tabPosition" type="border-card" height="600">
            <el-tab-pane label="亲友列表">
                <el-table :data="tableData" height="100%" :header-cell-style="tableHeaderColor" stripe
                    style="width: 100%">
                    <el-table-column type="index" align="center ">
                    </el-table-column>
                    <el-table-column prop="rela_id" label="好友账号" align="center">
                    </el-table-column>
                    <el-table-column prop="read" label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="text" size="medium">查看记录</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="删除" align="center">
                        <template slot-scope="scope">
                            <el-button type="danger" icon="el-icon-delete" size="mini"
                                @click="handleDelete(scope.$index, scope.row)">
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="待通过申请">
                <el-table :data="tableData" height="100%" :header-cell-style="tableHeaderColor" stripe
                    style="width: 100%">
                    <el-table-column type="index" align="center ">
                    </el-table-column>
                    <el-table-column prop="apply_rela_id" label="申请人账号" align="center">
                    </el-table-column>
                    <el-table-column prop="apply_message" label="描述" align="center">
                    </el-table-column>
                    <el-table-column prop="read" label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="text" size="medium">加他/她为好友</el-button>
                            <el-button @click="handleClick(scope.row)" type="text" size="medium">拒绝好友申请</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="添加新好友">
                <el-form ref="form" :model="form" label-width="130px" style="font-weight:bold">
                    <el-form-item label="对方用户名">
                        <el-input v-model="form.other_id" placeholder="请输入对方用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="请输入验证信息">
                        <el-input type="textarea" v-model="form.message" placeholder="向ta打个招呼吧"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary">确认</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
                                    </el-tabs> -->
        <div style="font-size: 28px;">亲友管理</div>
        <!-- <button @click="$router.push({ name: 'examplePage3' })">跳转</button> -->
        <el-divider></el-divider>

        <el-input style="width: 500px" placeholder="请输入用户姓名查询" v-model="search" clearable>
        </el-input>

        <el-table :data="tableData.filter(data => !search || data.realname.toLowerCase().includes(search.toLowerCase()))"
            style="width: 100%" border :header-cell-style="{ 'text-align': 'center' }"
            :cell-style="{ 'text-align': 'center' }">

            <el-table-column type="index" width="40"></el-table-column>
            <!-- <el-table-column label="手机号" prop="phone" width="150"></el-table-column> -->

            <el-table-column label="用户姓名" prop="realname">
            </el-table-column>


            <el-table-column label="检测记录查询" width="180">
                <template v-slot="scope3">
                    <el-popover placement="right" trigger="click" width="auto">
                        <div style="text-align: center;font-size: 20px">请选择查询项目</div>
                        <el-button @click="getECG(scope3.row)">
                            静态心电
                        </el-button>
                        <el-button @click="getECGHolter(scope3.row)">
                            动态心电
                        </el-button>
                        <el-button slot="reference" type="info">查看</el-button>
                    </el-popover>
                </template>
            </el-table-column>

            <el-table-column label="操作">
                <template v-slot:default="scope4">
                    <el-button type="danger" @click="handleDelete(scope4.$index, scope4.row)">删除</el-button>
                </template>
            </el-table-column>

        </el-table>

        <div style="padding-top: 40px">

            <div style="font-size: 24px;padding-bottom: 10px">添加新用户</div>
            <el-row>
                <el-radio v-model="authType" label="0">用户名+密码</el-radio>
                <!-- <el-radio v-model="authType" label="1">手机号</el-radio> -->
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-input placeholder="请输入用户名" v-model="username" v-if="authType == 0">
                    </el-input>
                </el-col>
                <el-col :span="6">
                    <el-input placeholder="请输入密码" v-model="password" v-if="authType == 0">
                    </el-input>
                </el-col>
                <el-col :span="6">
                    <el-input placeholder="请输入手机号" v-model="phone" v-if="authType == 1">
                    </el-input>
                </el-col>
            </el-row>
            <el-row>
                <el-button @click="addNewUser" type="primary">添加</el-button>
            </el-row>

        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            // tabPosition: 'left',
            // form: {
            //     other_id: '',
            //     message: ''
            // },
            size: '',
            // psInfoVisible: false,
            tableData: [
            ],
            username: '',
            password: '',
            authType: '0',
            phone: '',
            search: ''
        }
    },
    //监听属性 类似于data概念
    computed: {
        crossPlatformStatus: {
            get() { return this.$store.state.module.crossPlatform },
            set(val) { this.$store.commit('module/updateCrossPlatform', val) }
        }

    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        // tableHeaderColor() {
        //     return "color:#000 ;font-size:16px;text-align:center";
        // },
        handleDelete(index, row) {
            console.log("看看删除啥", index, row);
            console.log(this.uid, row.relid,);
            this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http({
                    url: this.$http.adornUrl("/personal/personalrelation/app/deleteRelation"),
                    method: "get",
                    params: this.$http.adornParams({
                        uid: this.uid,
                        relId: row.relid
                    }),
                }).then(({ data }) => {
                    if (data && data.code === 0) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getUserList()
                    } else {
                        console.log(data);
                        this.$message({
                            type: 'info',
                            message: data.msg
                        });
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        getUserList() {
            this.$http({
                url: this.$http.adornUrl("/personal/personalrelation/app/getRelation"),
                method: "get",
                params: this.$http.adornParams({
                    uid: this.uid
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log(data);
                    this.tableData = data.relations
                } else {
                    console.log(data);
                    this.$message({
                        message: data.msg,
                        type: "warning",
                    });
                }
            });
        },
        getECG(e) {
            console.log("看看2023年1月8日");
            console.log(e);
            this.crossPlatformStatus = true
            let uid = e.relid
            let father_uid = this.uid
            this.$router.push({
                name: 'HistorySingoJump',
                query: {
                    uid: uid,
                    father_uid: father_uid,
                }
            })
        },
        getECGHolter(e) {
            console.log("看看2023年1月8日");
            console.log(e);
            this.crossPlatformStatus = true
            let uid = e.relid
            let father_uid = this.uid
            this.$router.push({
                name: 'HistorySingoHolterJump',
                query: {
                    uid: uid,
                    father_uid: father_uid,
                }
            })
        },
        get8ECGHolter(e) {
            console.log("看看2023年1月8日");
            console.log(e);
            this.crossPlatformStatus = true
            let uid = e.relid
            let father_uid = this.uid
            this.$router.push({
                name: 'HistoryEightJump',
                query: {
                    uid: uid,
                    father_uid: father_uid,
                }
            })
        },
        addNewUser() {
            if (this.userNameOrPhone == "") {
                this.$alert('未填写用户信息', '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                    }
                });
            } else {
                let that = this
                this.$http({
                    url: this.$http.adornUrl("/personal/personalrelation/app/addRelation"),
                    method: "post",
                    data: this.$http.adornData({
                        // userPhone: that.userNameOrPhone,
                        // docId: this.$store.state.doctor_station.docid,
                        uid: this.uid,
                        username: this.username,
                        password: this.password,
                        phone: this.phone,
                        authType: this.authType,
                    }),
                }).then(({ data }) => {
                    if (data && data.code === 0) {
                        console.log("看看数据");
                        console.log(data);
                        this.$message({
                            message: "添加成功！",
                            type: "success",
                        });
                        this.username = ''
                        this.password = ''
                        this.phone = ''
                        this.getUserList()
                    } else {
                        console.log(data);
                        this.$message({
                            message: data.msg,
                            type: "warning",
                        });
                    }
                });
            }
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.uid = this.$store.state.personal_module.uid
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.getUserList()
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
::v-deep .el-tabs__item {
    font-size: 16px !important;
    font-weight: bold
}

.blank {
    border-radius: 4px;
    height: 18px;
}
</style>